import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeroImageText.module.scss';
import Image from '../../components/Image';

const HeroImageText = ({ image, title, text }) => {
    return (
        <div className={styles['HeroImageText']}>
            <div className={styles['HeroImageText__ImageWrapper']}>
                <Image {...image} />
            </div>
            <div className={styles['HeroImageText__ContentWrapper']}>
                <div className={styles['HeroImageText__Content']}>
                    <h1 className={styles['HeroImageText__Title']}>{title}</h1>
                    <p className={styles['HeroImageText__Text']}>{text}</p>
                </div>
            </div>
        </div>
    );
};

HeroImageText.propTypes = {
    image: PropTypes.object,
};

HeroImageText.defaultProps = {
    image: {},
};

export default HeroImageText;
