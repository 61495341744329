import React from 'react';
import PropTypes from 'prop-types';

import { snakeToPascal } from '../../utils/caseconverters';
import dynamic from 'next/dynamic';
import styles from './StreamField.module.scss';

const HelpBlock = dynamic(() => import('../HelpBlock'));
const CardListHalf = dynamic(() => import('../CardListHalf'));
const CardList = dynamic(() => import('../CardList'));
const Collage = dynamic(() => import('../Collage'));
const Wysiwyg = dynamic(() => import('../Wysiwyg'));
const InfoBlock = dynamic(() => import('../InfoBlock'));
const Faq = dynamic(() => import('../Faq'));
const ListModule = dynamic(() => import('../ListModule'));
const CardListModule = dynamic(() => import('../CardListModule'));
const ImageGallery = dynamic(() => import('../ImageGallery'));
const MapIframe = dynamic(() => import('../Map'));
const PostSegment = dynamic(() => import('../PostSegment'));
const VideoBlock = dynamic(() => import('../VideoBlock'));
const AnchorBlock = dynamic(() => import('../AnchorBlock'));
const ContactList = dynamic(() => import('../ContactList'));
const ImageBlock = dynamic(() => import('../ImageBlock'));
const ImageListBlock = dynamic(() => import('../ImageListBlock'));
const FormBlock = dynamic(() => import('../FormBlock'));
const Stepper = dynamic(() => import('../Stepper'));
const CookieBlock = dynamic(() => import('../CookieBlock'));
const IframeBlock = dynamic(() => import('../IframeBlock'), {
    ssr: false,
});

const Components = {
    HelpBlock,
    CardHalfListBlock: CardListHalf,
    CardListBlock: CardList,
    CollageBlock: Collage,
    ContactListBlock: ContactList,
    WysiwygBlock: Wysiwyg,
    InfoBlock,
    ImageListBlock,
    FaqBlock: Faq,
    ListBlock: ListModule,
    LinkListBlock: ListModule,
    NewsBlock: CardListModule,
    CardListModuleBlock: CardListModule,
    ImageBlock: ImageBlock,
    ImageGalleryBlock: ImageGallery,
    MapPointBlock: MapIframe,
    ProjectListBlock: CardListModule,
    PostSegmentBlock: PostSegment,
    VideoBlock: VideoBlock,
    AnchorBlock: AnchorBlock,
    FormBlock: FormBlock,
    Stepper,
    CookiesBlock: CookieBlock,
    IframeBlock,
};

const StreamField = ({ items, modifier }) => {
    if (!items.length) {
        return null;
    }
    const dynamicComponents = items.map((item) => {
        item.component = snakeToPascal(item.type);
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        return (
            <div
                key={item.id}
                id={`sf-${item.id}`}
                className={`StreamField__Component StreamField__Component--${item.component}`}
            >
                <Component id={item.id} {...item.value} modifier={modifier} />
            </div>
        );
    });

    return <div className={styles['StreamField']}>{dynamicComponents}</div>;
};

StreamField.propTypes = {
    items: PropTypes.array,
};

StreamField.defaultProps = {
    items: [],
};

export default StreamField;
