import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import isEmpty from '../../utils/isEmpty';
import Notice from '../../components/Notice';
import StreamField from '../../components/StreamField';

import styles from './HomePage.module.scss';

import HeroImageText from '../../components/HeroImageText/HeroImageText';

const HomePage = ({ hero, notices, contentBlocks, image }) => {
    return (
        <div className={styles['HomePage']}>
            <HeroImageText {...hero} image={image} />
            {!isEmpty(notices) && (
                <div className={styles['HomePage__NoticeWrapper']}>
                    {notices.map((notice) => (
                        <Notice key={notice.href} {...notice} />
                    ))}
                </div>
            )}
            {!isEmpty(contentBlocks) && <StreamField items={contentBlocks} />}
        </div>
    );
};

HomePage.propTypes = {
    hero: PropTypes.object,
    notices: PropTypes.array,
};

HomePage.defaultProps = {
    hero: {},
    notices: [],
};

export default basePageWrap(HomePage);
