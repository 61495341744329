import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import classNames from 'classnames';
import styles from './Notice.module.scss';

const Notice = ({ href, title, modifier }) => {
    const noticeClass = classNames(styles['Notice'], {
        [styles['Notice--NewsPage']]: modifier === 'NewsPage',
    });

    return (
        <div className={noticeClass}>
            <div className={styles['Notice__Container']}>
                <img
                    src={'/assets/svg/notice.svg'}
                    alt="Notis"
                    className={styles['Notice__Icon']}
                />
                <a href={href} className={styles['Notice__Link']}>
                    <h2 className={styles['Notice__Text']}>{title}</h2>
                </a>
            </div>
        </div>
    );
};

Notice.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
};

Notice.defaultProps = {
    href: '',
    title: '',
};

export default Notice;
